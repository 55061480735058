<template>
    <div id="page-wrapper">
        <top-header></top-header>
        <side-navbar></side-navbar>
        <main-content title="Platform Speed">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body text-center">
                            <label>
                                <input type="checkbox" v-model="isDailyReport" @change.prevent="onReportChange" />
                                Daily Report
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <template v-if="loadedTrades && checkAllDataLoaded()">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Execution Speed</h4>
                            </div>
                            <div class="card-body">
                                <b-table :fields="executionSpeedFields" :items="executionSpeedItems" class="text-center"></b-table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">Execution Tolerance</h4>
                            </div>
                            <div class="card-body">
                                <b-table :fields="executionToleranceFields" :items="executionToleranceItems" class="text-center"></b-table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <h1>Loaded {{tradesCount | number(0)}} trades</h1>
                <h1>Loaded {{tradeDetailsCount | number(0)}} trade details</h1>
            </template>
        </main-content>
    </div>
</template>

<script>
import TopHeader from '@/components/TopHeader';
import SideNavbar from '@/components/SideNavbar';
import MainContent from '@/components/MainContent';
import { startOfDay, isBefore, parse, parseJSON, format, subBusinessDays, addBusinessDays, startOfToday, addHours, subDays, addDays } from 'date-fns';

export default {
    data() {
        const now = new Date();
        const yesterday = startOfDay(subBusinessDays(now, 1));
        const today = startOfToday(now);
        
        let weekStart = subDays(today, 1);
        while(weekStart.getDay() !== 1) {
            weekStart = subDays(weekStart, 1);
        }

        let weekEnd = weekStart;
        while(weekEnd.getDay() !== 0) {
            weekEnd = addDays(weekEnd, 1);
        }
        
        return {
            now: now,
            yesterday: yesterday,
            today: today,
            weekStart: weekStart,
            weekEnd: weekEnd,
            isDailyReport: true,
            subscribed: false,
            loadedTrades: false,
            trades: {},
            tradesCount: 0,
            tradeDetails: {},
            tradeDetailsCount: 0,
            executionSpeedFields: [
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {
                    key: 'market',
                    label: 'Market'
                },
                {
                    key: 'tradeType',
                    label: 'TradeType'
                },
                {
                    key: 'size',
                    label: 'Size'
                },
                {
                    key: 'speed',
                    label: 'Speed (ms)'
                },
                {
                    key: 'tolerance',
                    label: 'Tick Tolerance'
                },
                {
                    key: 'triggeredBy',
                    label: 'Triggered By'
                }
            ],
            executionSpeedItems: [],
            executionToleranceFields: [
                {
                    key: 'timestamp',
                    label: 'Timestamp',
                    formatter: function(value) {
                        return format(value, 'dd/MM/yyyy HH:mm:ss');
                    }
                },
                {
                    key: 'market',
                    label: 'Market'
                },
                {
                    key: 'tradeType',
                    label: 'TradeType'
                },
                {
                    key: 'size',
                    label: 'Size'
                },
                {
                    key: 'speed',
                    label: 'Speed (ms)'
                },
                {
                    key: 'tolerance',
                    label: 'Tick Tolerance'
                },
                {
                    key: 'triggeredBy',
                    label: 'Triggered By'
                }
            ],
            executionToleranceItems: []
        };
    },
    components: {
        TopHeader,
        SideNavbar,
        MainContent
    },
    beforeMount() {
        this.$ws.on('trades', this.onTradesLoaded);
        this.$ws.on('tradedetails', this.onTradeDetailsLoaded);
        this.subscribed = true;
        
        let account = this.account;
        if(!account) {
            account = this.$store.state.activeAccount;
        }

        this.$ws.send({
            sessionID: 'NotImplemented',
            accountName: account,
            request: 'Trades',
            args: {}
        });
    },
    methods: {
        onTradesLoaded(event) {
            this.loadedTrades = true;

            const trades = event.response;

            for(const trade of trades) {
                this.trades[trade.id] = trade;
            }

            this.loadTradeDetails(trades);
        },
        loadTradeDetails(trades) {
            this.tradesCount = trades.length;

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }
            
            for(const trade of trades) {
                this.$ws.send({
                    accountName: account,
                    request: 'TradeDetails',
                    args: {
                        tradeID: trade.id,
                        accountName: trade.account
                    }
                });
            }
        },
        onTradeDetailsLoaded(event) {
            this.tradeDetailsCount++;

            const tradeID = event.request.args.tradeID;

            this.tradeDetails[tradeID] = event.response;

            const hasLoaded = this.checkAllDataLoaded();
            this.calculateExecutionSpeed(hasLoaded);
            this.calculateExecutionTolerance(hasLoaded);
        },
        calculateExecutionSpeed(hasLoaded) {
            if(hasLoaded) {
                const items = [];

                let executionSpeed = [];
                for(const tradeID in this.tradeDetails) {
                    const trade = this.trades[tradeID];

                    const entryTime = parse(trade.entryTime, 'dd/MM/yyyy HH:mm:ss', this.now);

                    if(this.isDailyReport) {
                        if(isBefore(entryTime, this.yesterday) || isBefore(this.today, entryTime)) {
                            continue;
                        }
                    } else {
                        if(isBefore(entryTime, this.weekStart) || isBefore(this.weekEnd, entryTime)) {
                            continue;
                        }
                    }

                    const platformTiming = this.tradeDetails[tradeID].PlatformTiming;

                    const tradeSpeed = {};

                    for(const component in platformTiming) {
                        for(const reason in platformTiming[component]) {
                            if(!tradeSpeed.hasOwnProperty(reason)) {
                                tradeSpeed[reason] = 0;
                            }

                            tradeSpeed[reason] += platformTiming[component][reason];
                        }
                    }

                    for(const reason in tradeSpeed) {
                        executionSpeed.push({
                            id: tradeID,
                            key: reason,
                            value: tradeSpeed[reason]
                        });
                    }
                }

                executionSpeed.sort((a, b) => {
                    if(a.value < b.value) {
                        return 1;
                    }

                    if(a.value > b.value) {
                        return -1;
                    }

                    return 0;
                });

                let min = Math.min(5, executionSpeed.length);

                for(let i = 0; i < min; i++) {
                    const speedEntry = executionSpeed[i];
                    const trade = this.trades[speedEntry.id];
                    const tradeDetails = this.tradeDetails[speedEntry.id];

                    let execution = null;

                    if(speedEntry.key === 'Entry') {
                        execution = tradeDetails.Executions[0];
                    } else if(speedEntry.key === 'Exit') {
                        execution = tradeDetails.Executions[tradeDetails.Executions.length - 1];
                    }

                    if(execution !== null) {
                        items.push({
                            timestamp: addHours(parseJSON(execution.DateTime), 5),
                            market: trade.instrument,
                            tradeType: execution.TradeType,
                            size: execution.FilledSize,
                            speed: speedEntry.value,
                            tolerance: parseFloat(execution.ToleranceTicks).toFixed(2),
                            triggeredBy: execution.TriggeredBy
                        });
                    }
                }

                this.executionSpeedItems = items;
            }
        },
        calculateExecutionTolerance(hasLoaded) {
            if(hasLoaded) {
                let items = [];

                let executions = [];
                for(const tradeID in this.tradeDetails) {
                    const trade = this.trades[tradeID];

                    const entryTime = parse(trade.entryTime, 'dd/MM/yyyy HH:mm:ss', this.now);

                    if(this.isDailyReport) {
                        if(isBefore(entryTime, this.yesterday) || isBefore(this.today, entryTime)) {
                            continue;
                        }
                    } else {
                        if(isBefore(entryTime, this.weekStart) || isBefore(this.weekEnd, entryTime)) {
                            continue;
                        }
                    }

                    const platformTiming = this.tradeDetails[tradeID].PlatformTiming;

                    const tradeSpeed = {};

                    for(const component in platformTiming) {
                        for(const reason in platformTiming[component]) {
                            if(!tradeSpeed.hasOwnProperty(reason)) {
                                tradeSpeed[reason] = 0;
                            }

                            tradeSpeed[reason] += platformTiming[component][reason];
                        }
                    }

                    for(const execution of this.tradeDetails[tradeID].Executions) {
                        let key = 'Entry';
                        if(execution.TradeType.endsWith('Exit')) {
                            key = 'Exit';
                        }

                        executions.push({
                            tradeID: tradeID,
                            speed: tradeSpeed[key],
                            ...execution
                        });
                    }
                }

                executions.sort((a, b) => {
                    if(a.ToleranceTicks < b.ToleranceTicks) {
                        return -1;
                    }

                    if(a.ToleranceTicks > b.ToleranceTicks) {
                        return 1;
                    }

                    return 0;
                });

                let min = Math.min(5, executions.length);

                for(let i = 0; i < min; i++) {
                    let execution = executions[i];

                    const trade = this.trades[execution.tradeID];
                    const tradeDetails = this.tradeDetails[execution.tradeID];

                    if(execution !== null) {
                        items.push({
                            timestamp: addHours(parseJSON(execution.DateTime), 5),
                            market: trade.instrument,
                            tradeType: execution.TradeType,
                            size: execution.FilledSize,
                            speed: execution.speed,
                            tolerance: parseFloat(execution.ToleranceTicks).toFixed(2),
                            triggeredBy: execution.TriggeredBy
                        });
                    }
                }

                this.executionToleranceItems = items;
            }
        },
        checkAllDataLoaded() {
            if(this.tradeDetailsCount < this.tradesCount) {
                return false;
            }

            for(const id in this.trades) {
                if(!this.tradeDetails.hasOwnProperty(id)) {
                    return false;
                }
            }

            this.unsubscribe();

            return true;
        },
        unsubscribe() {
            if(!this.subscribed) {
                return;
            }

            this.$ws.off('trades', this.onTradesLoaded);
            this.$ws.off('tradedetails', this.onTradeDetailsLoaded);
        },
        onReportChange() {
            const hasLoaded = this.checkAllDataLoaded();
            this.calculateExecutionSpeed(hasLoaded);
            this.calculateExecutionTolerance(hasLoaded);
        }
    }
}
</script>